import React from "react";
import cx from "classnames";
import { ReactComponent as QuietPlaceLogo } from "assets/img/svg/quiet_place.svg";
import Typography from "components/commons/Typography/Typography";
import "./SetupInstructions.scss";
import { Typography as TypographyType } from "../../../../constants/enums/typography";
import Button from "../../../../components/commons/Button/Button";
import { useHistoryBack } from "../../../../hooks/specific/useHistoryBack";
import { ExperienceTourRoutePath } from "../../router/ExperienceTourRoutePath";
import { useHistory } from "react-router-dom";

type SetupInstructionsProps = {
  className?: string;
};

/**
 * SetupInstructions Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<SetupInstructionsProps>}
 */
const SetupInstructions: React.FC<SetupInstructionsProps> = ({ className }) => {
  const handleBack = useHistoryBack();
  const history = useHistory();

  const classes: string = cx("setup-instructions", className);

  function handleRedirect(path: string): void {
    history.push(path, { from: location?.pathname });
  }

  return (
    <div className={classes}>
      <Typography
        as={TypographyType.Title}
        className="setup-instructions__title"
      >
        Avant de commencer
      </Typography>
      <div className="setup-instructions__content">
        <div className="setup-instructions__instruction">
          <QuietPlaceLogo
            className="setup-instructions__logo"
            width={70}
            height={100}
          />
          <Typography>Assurez-vous d'être dans le calme</Typography>
        </div>
      </div>
      <div className="setup-instructions__action-buttons">
        <Button className="setup-instructions__back-btn" onClick={handleBack}>
          Retour à l'étape précédente
        </Button>
        <Button
          className="setup-instructions__next-btn"
          onClick={() => handleRedirect(ExperienceTourRoutePath.Simulator)}
        >
          Étape suivante
        </Button>
      </div>
    </div>
  );
};

export default SetupInstructions;
