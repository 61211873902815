import React from "react";
import cx from "classnames";
import Button, { ButtonProps } from "components/commons/Button/Button";
import { AnonymousFunction } from "constants/types/anonymousFunction";
import "./ColumnSelection.scss";
import Typography from "../../../commons/Typography/Typography";
import { Typography as TypographyType } from "constants/enums/typography";

type ColumnSelection = {
  button?: {
    value: string;
    props?: ButtonProps;
    onClick?: AnonymousFunction;
  };
  customButton?: JSX.Element;
  className?: string;
  title: string | React.ReactElement;
  style?: {
    backgroundImg?: any;
    invertedColor?: boolean;
  };
  subtitle?: string | React.ReactElement;
};

/**
 * ColumnSelection Functional Component
 * @param backgroundImage
 * @param {string} className - used to set a class on a higher element tag
 * @param {{ value: string, props: ButtonProps }} button
 * @param {string} subtitle
 * @param {string} title
 * @constructor
 * @return {React.FC<ColumnSelection>}
 */
const ColumnSelection: React.FC<ColumnSelection> = ({
  className,
  button,
  style,
  subtitle,
  title,
  customButton,
}) => {
  const classes: string = cx("column-selection", className, {
    "column-selection--has-background": style?.backgroundImg,
    "column-selection--inverted-color": style?.invertedColor,
  });

  return (
    <div
      className={classes}
      style={{ backgroundImage: `url(${style?.backgroundImg})` }}
    >
      <Typography
        as={TypographyType.Title}
        className={"column-selection__title"}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          as={TypographyType.Body}
          className={"column-selection__subtitle"}
        >
          {subtitle}
        </Typography>
      )}
      {customButton && customButton}
      {button && (
        <Button
          {...(button.props || {})}
          invertedColor={style?.invertedColor}
          onClick={button?.onClick}
        >
          {button.value}
        </Button>
      )}
    </div>
  );
};

export default ColumnSelection;
