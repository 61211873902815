import React from "react";
import { useLocation } from "react-router-dom";
import cx from "classnames";
import { motion } from "framer-motion";
import Button from "components/commons/Button/Button";
import Typography from "components/commons/Typography/Typography";
import { hearingTestViewOrder } from "constants/enums/routePath";
import { Typography as TypographyType } from "constants/enums/typography";
import { useRedirect } from "hooks/specific/useRedirect";
import { useHistoryBack } from "hooks/specific/useHistoryBack";
import "./ViewWrapper.scss";

export type ViewWrapperProps = {
  className?: string;
  title?: string;
  next?: boolean;
  disableNext?: boolean | ((...args: any[]) => boolean);
  back?: boolean;
};

/**
 * ViewWrapper Functional Component
 * @param children
 * @param {string} className - used to set a class on a higher element tag
 * @param title
 * @param next
 * @param disableNext
 * @param back
 * @constructor
 * @return {React.FC<ViewWrapperProps>}
 */
const ViewWrapper: React.FC<ViewWrapperProps> = ({
  children,
  className,
  title,
  next = true,
  disableNext = false,
  back = true,
}) => {
  const redirect = useRedirect();
  const handleBack = useHistoryBack();
  const { pathname } = useLocation();
  const _disableNext =
    typeof disableNext === "function" ? disableNext() : disableNext;

  const classes: string = cx("hearing-test-view-wrapper", className);

  function _handleRedirect() {
    if (next && hearingTestViewOrder[pathname]?.next)
      redirect(hearingTestViewOrder[pathname].next as string);
  }

  return (
    <motion.div className={classes}>
      {/*<StepperProgress/>*/}
      <Typography
        as={TypographyType.Title}
        className="hearing-test-view-wrapper__title"
      >
        {title}
      </Typography>
      <div className="hearing-test-view-wrapper__content">{children}</div>
      <div className="hearing-test-view-wrapper__action-buttons">
        {!!back && (
          <Button
            className="hearing-test-view-wrapper__back-btn"
            onClick={handleBack}
          >
            Retour à l'étape précédente
          </Button>
        )}
        {!!next && !pathname.includes("hearing-test/measurement") && (
          <Button
            className="hearing-test-view-wrapper__next-btn"
            onClick={_handleRedirect}
            disabled={_disableNext}
          >
            {pathname === "/hearing-test/setup-instructions"
              ? "Commencer"
              : "Suivant"}
          </Button>
        )}
      </div>
    </motion.div>
  );
};

export default ViewWrapper;
