import cx from "classnames";

interface CatalogProductsProps {
  className?: string;
}

const CatalogProducts: React.FC<CatalogProductsProps> = ({ className }) => {
  const classes = cx("catalog_products", className);

  return (
    <div className={classes}>
      <iframe
        src="https://catalogue.sonoly.fr/"
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
        width="100%"
        height="100%"
        scrolling="auto"
      />
    </div>
  );
};

export default CatalogProducts;
