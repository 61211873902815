export * from "features/experience-tour/router/ExperienceTourRoutePath";
export * from "features/product-test/router/ProductTestRoutePath";

export const MeasurementPath = "/hearing-test/measurement/:type";
export const QuestionsPath = "/hearing-test/questions/:part";

export enum MeasurementType {
  RightLow = "right-1k",
  Right2k = "right-2k",
  Right4k = "right-4k",
  LeftLow = "left-1k",
  Left2k = "left-2k",
  Left4k = "left-4k",
}

export const RoutePath = {
  Root: "/",
  All: "*",
  SplashScreen: "/splashscreen",
  Login: "/signin",
  Dashboard: "/dashboard",
  HearingTestSignChoices: "/hearing-test/sign/choices",
  HearingTestSignUp: "/hearing-test/sign/up",
  HearingTestSignIn: "/hearing-test/sign/in",
  HearingTestWelcome: "/hearing-test/welcome",
  HearingTestIntro: "/hearing-test/introduction",
  HearingTestSetupInstructions: "/hearing-test/setup-instructions",
  HearingTestMeasurement: `${MeasurementPath}(${Object.values(
    MeasurementType
  ).join("|")})`,
  HearingTestAgeRange: "/hearing-test/age-range",
  HearingTestQuestions: `${QuestionsPath}(1|2)`,
  HearingTestRecontact: "/hearing-test/recontact",
  HearingTestThanks: "/hearing-test/thanks",
  ScheduleSignChoices: "/schedule/sign/choices",
  ScheduleSignIn: "/schedule/sign/in",
  ScheduleSignUp: "/schedule/sign/up",
  ScheduleWelcome: "/schedule/welcome",
  ScheduleCalendar: "/schedule/calendar",
  ScheduleThanks: "/schedule/thanks",
  CatalogProducts: "/catalog-products",
};

/**
 * Ignored paths (user authed or not)
 * Note: use a duplicate enums is more efficient than looping to the first one (cf. algorithm complexity)
 */
export enum IgnoredRoutePath {
  Root = "/",
  Login = "/signin",
  SplashScreen = "/splashscreen",
}

/**
 * Inactive paths
 * Used to disable some path of the application
 * TODO: with custom browser history
 */
export enum InactiveRoutePath {}

/**
 * Used to compute automatically a path relatives to Measurement
 */
export const ComputedMeasurementPath = {
  RightLow: MeasurementPath.replace(":type", MeasurementType.RightLow),
  Right2k: MeasurementPath.replace(":type", MeasurementType.Right2k),
  Right4k: MeasurementPath.replace(":type", MeasurementType.Right4k),
  LeftLow: MeasurementPath.replace(":type", MeasurementType.LeftLow),
  Left2k: MeasurementPath.replace(":type", MeasurementType.Left2k),
  Left4k: MeasurementPath.replace(":type", MeasurementType.Left4k),
};

/**
 * Used to compute automatically a path relatives to Questions
 */
export const ComputedQuestionsPath = {
  1: QuestionsPath.replace(":part", "1"),
  2: QuestionsPath.replace(":part", "2"),
};

/**
 * Used by ViewWrapper to find automatically the next view to render
 */
export const hearingTestViewOrder = {
  [RoutePath.HearingTestIntro]: {
    order: 1,
    next: RoutePath.HearingTestSetupInstructions,
  },
  [RoutePath.HearingTestSetupInstructions]: {
    order: 2,
    next: ComputedMeasurementPath.RightLow,
  },
  [ComputedMeasurementPath.RightLow]: {
    order: 3,
    next: ComputedMeasurementPath.Right2k,
  },
  [ComputedMeasurementPath.Right2k]: {
    order: 4,
    next: ComputedMeasurementPath.Right4k,
  },
  [ComputedMeasurementPath.Right4k]: {
    order: 5,
    next: ComputedMeasurementPath.LeftLow,
  },
  [ComputedMeasurementPath.LeftLow]: {
    order: 6,
    next: ComputedMeasurementPath.Left2k,
  },
  [ComputedMeasurementPath.Left2k]: {
    order: 7,
    next: ComputedMeasurementPath.Left4k,
  },
  [ComputedMeasurementPath.Left4k]: {
    order: 8,
    next: ComputedQuestionsPath["1"],
  },
  [ComputedQuestionsPath["1"]]: { order: 9, next: ComputedQuestionsPath["2"] },
  [ComputedQuestionsPath["2"]]: {
    order: 10,
    next: RoutePath.HearingTestSignUp,
  },
  [RoutePath.HearingTestSignUp]: {
    order: 11,
    next: RoutePath.HearingTestThanks,
  },
};
