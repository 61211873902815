export enum EarTour {
  Choices = "video-choices",
  Video = "ear-tour",
  VirtualVideo = "virtual-ear-tour",
}

export enum HearingLossSimulator {
  SetupInstructions = "hearing-loss-setup",
  Simulator = "hearing-loss-simulator",
}

export const ExperienceTourRoutePath = {
  Choices: "/experience-tour/choices",
  VideoChoices: `/experience-tour/${EarTour.Choices}`,
  Video: `/experience-tour/${EarTour.Video}`,
  VirtualVideo: `/experience-tour/${EarTour.VirtualVideo}`,
  SetupInstructions: `/experience-tour/${HearingLossSimulator.SetupInstructions}`,
  Simulator: `/experience-tour/${HearingLossSimulator.Simulator}`,
  Questions: "/experience-tour/questions",
};
