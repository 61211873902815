import QuestionChoiceImg from "assets/img/backgrounds/experience-tour_choices-questions.png";
import ExperienceTourVideo from "assets/img/backgrounds/experience-tour_choices_video.png";
import ExperienceTourVitualVideo from "assets/img/backgrounds/experience-tour_choices_virtual-video.png";
import cx from "classnames";
import Cols from "components/commons/Cols/Cols";
import ColumnSelection from "components/containers/Columns/ColumnSelection/ColumnSelection";
import { LocaleNamespace } from "config/intl/helpers";
import {
  EarTour,
  ExperienceTourRoutePath,
} from "features/experience-tour/router/ExperienceTourRoutePath";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import "./ExperienceTourChoices.scss";
import AudioButton from "components/commons/AudioButton/AudioButton";

type ExperienceTourChoicesProps = {
  className?: string;
};

/**
 * ExperienceTourChoices Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<ExperienceTourChoicesProps>}
 */
const ExperienceTourChoices: React.FC<ExperienceTourChoicesProps> = ({
  className,
}) => {
  const { t } = useTranslation(LocaleNamespace.Pages);
  const history = useHistory();

  const classes: string = cx("experience-tour-choices", className);

  function handleRedirect(path: string): void {
    history.push(path, { from: location?.pathname });
  }

  return (
    <Cols className={classes}>
      <ColumnSelection
        customButton={
          <AudioButton
            isStatic
            onClick={() => handleRedirect(`/experience-tour/${EarTour.Video}`)}
          />
        }
        className={
          "experience-tour-choices__column-selection experience-tour-choices__simulator-column-selection"
        }
        title={
          <Trans t={t} i18nKey="experience-tour.choices.videoPart.title" />
        }
        subtitle={t("experience-tour.choices.videoPart.subtitle")}
        style={{
          backgroundImg: ExperienceTourVideo,
          invertedColor: true,
        }}
      />
      <ColumnSelection
        customButton={
          <AudioButton
            isStatic
            onClick={() => handleRedirect(ExperienceTourRoutePath.VirtualVideo)}
          />
        }
        className={"experience-tour-choices__column-selection"}
        title={
          <Trans
            t={t}
            i18nKey="experience-tour.choices.virtualVideoPart.title"
          />
        }
        subtitle={t("experience-tour.choices.virtualVideoPart.subtitle")}
        style={{
          backgroundImg: ExperienceTourVitualVideo,
          invertedColor: true,
        }}
      />
      <ColumnSelection
        button={{
          value: t("experience-tour.choices.questionPart.btn"),
          onClick: () => handleRedirect(ExperienceTourRoutePath.Questions),
        }}
        className={"experience-tour-choices__column-selection"}
        title={
          <Trans t={t} i18nKey="experience-tour.choices.questionPart.title" />
        }
        subtitle={t("experience-tour.choices.questionPart.subtitle")}
        style={{ backgroundImg: QuestionChoiceImg, invertedColor: true }}
      />
    </Cols>
  );
};

export default ExperienceTourChoices;
