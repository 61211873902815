import React from "react";
import cx from "classnames";
import Input from "components/commons/Input/Input";
import Textarea from "components/commons/Textarea/Textarea";
import { FormDataValues } from "constants/types/formDataValues";
import { UseFormRegister } from "react-hook-form/dist/types/form";
import { FieldErrors } from "react-hook-form/dist/types/errors";

type HookFormControlProps = {
  className?: string;
  autoFocus?: boolean;
  data: FormDataValues;
  handleRegister: UseFormRegister<any>;
  errors?: FieldErrors<any>;
};

/**
 * HookFormControl Functional Component
 * ! Used for React Hook Form
 * @param {string} className - used to set a class on a higher element tag
 * @param data
 * @param errors
 * @param handleRegister
 * @constructor
 * @return {React.FC<HookFormControlProps>}
 */
const HookFormControl: React.FC<HookFormControlProps> = ({
  className,
  autoFocus,
  data,
  errors,
  handleRegister,
}) => {
  const Component = data.type === "textarea" ? Textarea : Input;
  const {
    name,
    required,
    minLength,
    maxLength,
    pattern,
    type,
    label,
    placeholder,
    autocomplete,
    min,
    max,
    ...rest
  } = data;

  const classes: string = cx("hook-form-input", className);

  /**
   * Return an array of error messages or false if no errors
   * @param {string} key - The form errors key (equals to input name)
   * @returns {string[]|boolean}
   */
  function handleErrors(key: string): string[] | undefined {
    // @ts-ignore
    if (!errors?.[key]?.types) return;
    // @ts-ignore
    return Object.values(errors[key].types);
  }

  return (
    <Component
      {...handleRegister(name, {
        required: required,
        minLength: minLength,
        maxLength: maxLength,
        max: max,
        pattern: pattern,
      })}
      {...rest}
      type={type}
      className={classes}
      label={label}
      placeholder={placeholder}
      autoComplete={autocomplete}
      min={min?.value}
      max={max?.value}
      autoFocus={autoFocus ?? false}
      errors={handleErrors(name)}
    />
  );
};

export default HookFormControl;
