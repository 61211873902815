import { LocaleNamespace } from "config/intl/helpers";
import { TFunction } from "react-i18next";
import { FormDataValues as FormDataValuesType } from "constants/types/formDataValues";

export enum FormDataKey {
  Firstname = "firstname",
  Lastname = "lastname",
  Email = "email",
  Address = "address",
  City = "city",
  PostalCode = "postal_code",
  Gender = "gender",
  Birthdate = "birthdate",
}

export function FormDataValues(
  t: TFunction<LocaleNamespace>
): (FormDataValuesType & { name: FormDataKey })[] {
  const errorMessages = {
    required: t(`${LocaleNamespace.Errors}:form.input.required`),
    minLength: (n: number) =>
      t(`${LocaleNamespace.Errors}:form.input.minLength_plural`, { number: n }),
    maxLength: (n: number) =>
      t(`${LocaleNamespace.Errors}:form.input.maxLength_plural`, { number: n }),
    max: (n: number) =>
      t(`${LocaleNamespace.Errors}:form.input.max`, { number: n }),
    email: t(`${LocaleNamespace.Errors}:form.input.email`),
    number: t(`${LocaleNamespace.Errors}:form.input.number`),
  };

  return [
    {
      name: FormDataKey.Lastname,
      label: t("schedule.sign-up.inputs.lastname.label"),
      autocomplete: "off",
      required: {
        value: true,
        message: errorMessages.required,
      },
      minLength: {
        value: 2,
        message: errorMessages.minLength(2),
      },
    },
    {
      name: FormDataKey.Address,
      autocomplete: "off",
      label: t("schedule.sign-up.inputs.address.label"),
      required: {
        value: false,
        message: errorMessages.required,
      },
      minLength: {
        value: 8,
        message: errorMessages.minLength(8),
      },
    },
    {
      name: FormDataKey.Firstname,
      label: t("schedule.sign-up.inputs.firstname.label"),
      autocomplete: "off",
      required: {
        value: true,
        message: errorMessages.required,
      },
      minLength: {
        value: 2,
        message: errorMessages.minLength(2),
      },
    },
    {
      name: FormDataKey.Email,
      autocomplete: "off",
      label: t("schedule.sign-up.inputs.email.label"),
      required: {
        value: true,
        message: errorMessages.required,
      },
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: errorMessages.email,
      },
    },
    {
      name: FormDataKey.PostalCode,
      label: t("schedule.sign-up.inputs.postal-code.label"),
      required: {
        value: false,
        message: errorMessages.required,
      },
      minLength: {
        value: 5,
        message: errorMessages.minLength(5),
      },
      maxLength: {
        value: 5,
        message: errorMessages.maxLength(5),
      },
    },
  ];
}
