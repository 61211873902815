import {
  ExperienceTourRoutePath,
  ProductTestRoutePath,
  RoutePath,
} from "constants/enums/routePath";

interface DashboardMenuItem {
  untranslatedTitle: string;
  linkTo: string;
  key: string;
}

export const menuItems: DashboardMenuItem[] = [
  {
    untranslatedTitle: "Tester \n son audition",
    linkTo: RoutePath.HearingTestWelcome,
    key: "hearing-test",
  },
  {
    untranslatedTitle: "Simuler une perte auditive",
    linkTo: ExperienceTourRoutePath.SetupInstructions,
    key: "experience-tour-setup",
  },
  {
    untranslatedTitle: "Catalogue Produits",
    linkTo: RoutePath.CatalogProducts,
    key: "product-catalog",
  },
  {
    untranslatedTitle: "Prendre un rendez-vous",
    linkTo: RoutePath.ScheduleSignChoices,
    key: "schedule",
  },
  {
    untranslatedTitle: "Écouter un produit",
    linkTo: ProductTestRoutePath.Choices,
    key: "product-test",
  },
  {
    untranslatedTitle: "Experience tour",
    linkTo: ExperienceTourRoutePath.Choices,
    key: "experience-tour",
  },
];
