import axios, { AxiosRequestConfig } from "axios";
import TokenService from "services/token";

const resolveBaseURL = () => {
  const exceptions = ["ear-tour", "test-auditif"];
  const url = document.location.href;

  return url.includes(".netlify.app") &&
    !exceptions.some((e) => url.includes(e))
    ? "https://api.audiowizard.fr"
    : process.env.REACT_APP_API_DOMAIN;
};

const axiosConfig = {
  baseURL: resolveBaseURL(),
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

/**
 * Use apiAxiosInstance for token handling in the request
 */
const apiAxiosInstance = axios.create(axiosConfig);

const beforeRequestSent = (config: AxiosRequestConfig) => {
  const token = TokenService.getUserToken();

  if (token !== null) {
    config.headers = {
      ...config.headers,
      Authorization: "Bearer " + token,
    };
  }

  return config;
};

apiAxiosInstance.interceptors.request.use(beforeRequestSent, (error) => error);

export default apiAxiosInstance;
