import React, { useEffect } from "react";
import ProtectedRoute from "components/router/routes/ProtectedRoute";
import { SplashScreen } from "components/containers/SplashScreen/SplashScreen";
import { default as Dashboard } from "features/dashboard/views/Dashboard";
import {
  ScheduleCalendar,
  ScheduleSignChoices,
  ScheduleSignIn,
  ScheduleSignUp,
  ScheduleWelcome,
} from "features/schedule/views";
import {
  HearingTestIntroduction,
  HearingTestMeasurement,
  HearingTestQuestions,
  HearingTestSetupInstructions,
  HearingTestSign,
  HearingTestSignIn,
  HearingTestSignUp,
  HearingTestThanks,
  HearingTestWelcome,
} from "features/hearing-test/views";
import CatalogProducts from "features/catalog-products/views/CatalogProducts";
import ScheduleThanks from "features/schedule/views/Thanks/ScheduleThanks";
import ExperienceTourRoutes, {
  IExperienceTourRoute,
} from "features/experience-tour/router/ExperienceTourRoutes";
import ProductTestRoutes, {
  IProductTestRoutes,
} from "features/product-test/router/ProductTestRoutes";
import { RoutePath } from "constants/enums/routePath";
import Switcher from "../Switcher";
import Login from "features/login/Login";
import { Redirect, Route, useLocation } from "react-router-dom";
import Page404 from "./Page404";
import { usePatientSessionDestroy } from "hooks/specific/usePatientSessionDestroy";
import { usePatientRedirect } from "hooks/specific/usePatientRedirect";

interface IRoutes {
  authed: boolean;
}

/**
 * Routes Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IRoutes>}
 */
const Routes: React.FC<IRoutes> = ({ authed }) => {
  const shouldPatientSessionDestroy = usePatientSessionDestroy();
  // const shouldPatientRedirect = usePatientRedirect();
  const location = useLocation();

  useEffect(() => {
    shouldPatientSessionDestroy(location.pathname);
    // shouldPatientRedirect(location.pathname);
  }, [location.pathname]);

  return (
    <Switcher>
      <Route exact path={RoutePath.Root}>
        <Redirect to={RoutePath.SplashScreen} />
      </Route>
      <Route path={RoutePath.Login} component={Login} />
      <Route path={RoutePath.SplashScreen} component={SplashScreen} />

      <ProtectedRoute
        authed={authed}
        path={RoutePath.Dashboard}
        component={Dashboard}
      />
      <ProtectedRoute
        authed={authed}
        path={RoutePath.HearingTestSignChoices}
        component={HearingTestSign}
      />
      <ProtectedRoute
        authed={authed}
        path={RoutePath.HearingTestSignUp}
        component={HearingTestSignUp}
      />
      <ProtectedRoute
        authed={authed}
        path={RoutePath.HearingTestSignIn}
        component={HearingTestSignIn}
      />
      <ProtectedRoute
        authed={authed}
        path={RoutePath.HearingTestWelcome}
        component={HearingTestWelcome}
      />
      <ProtectedRoute
        authed={authed}
        path={RoutePath.HearingTestIntro}
        component={HearingTestIntroduction}
      />
      <ProtectedRoute
        authed={authed}
        path={RoutePath.HearingTestSetupInstructions}
        component={HearingTestSetupInstructions}
      />
      <ProtectedRoute
        authed={authed}
        path={RoutePath.HearingTestMeasurement}
        component={HearingTestMeasurement}
      />
      <ProtectedRoute
        authed={authed}
        path={RoutePath.HearingTestQuestions}
        component={HearingTestQuestions}
      />
      <ProtectedRoute
        authed={authed}
        path={RoutePath.HearingTestThanks}
        component={HearingTestThanks}
      />

      <ProtectedRoute
        authed={authed}
        path={RoutePath.ScheduleSignChoices}
        component={ScheduleSignChoices}
      />
      <ProtectedRoute
        authed={authed}
        path={RoutePath.ScheduleSignIn}
        component={ScheduleSignIn}
      />
      <ProtectedRoute
        authed={authed}
        path={RoutePath.ScheduleSignUp}
        component={ScheduleSignUp}
      />
      <ProtectedRoute
        authed={authed}
        path={RoutePath.ScheduleWelcome}
        component={ScheduleWelcome}
      />
      <ProtectedRoute
        authed={authed}
        path={RoutePath.ScheduleCalendar}
        component={ScheduleCalendar}
      />
      <ProtectedRoute
        authed={authed}
        path={RoutePath.ScheduleThanks}
        component={ScheduleThanks}
      />

      <ProtectedRoute
        authed={authed}
        path={RoutePath.CatalogProducts}
        component={CatalogProducts}
      />

      {ExperienceTourRoutes.map(
        (routeProps: IExperienceTourRoute, key: number) => (
          <ProtectedRoute {...routeProps} authed={authed} key={key} />
        )
      )}

      {ProductTestRoutes.map((routeProps: IProductTestRoutes, key: number) => (
        <ProtectedRoute {...routeProps} authed={authed} key={key} />
      ))}

      <Route path={RoutePath.All} component={Page404} />
    </Switcher>
  );
};

export default Routes;
