import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RoutePath } from "constants/enums/routePath";
import { LocaleNamespace } from "config/intl/helpers";


/**
 * Calculates the title value by using the current location
 */
export function useTitle(): string | undefined {
  const [calculatedTitle, setCalculatedTitle] = useState<string | null>(null);
  const location = useLocation();
  const { t, ready } = useTranslation(LocaleNamespace.Pages); // TODO: Custom hook w/ pre-path

  useEffect(() => {
    let _calcTitle = "";
    switch (true) {
      case location?.pathname?.includes(RoutePath.Dashboard):
        _calcTitle = t(`dashboard.header.title`);
        break;
      case location?.pathname?.includes("hearing-test"):
        _calcTitle = "Tester son audition";
        break;
      case location?.pathname?.includes("schedule"):
        _calcTitle = "Prendre un Rendez-vous avec un professionnel";
        break;
      case location?.pathname?.includes("experience-tour"):
        if (location?.pathname?.includes("video")) {
          _calcTitle = "Visite de l'oreille";
        }
        else if (location?.pathname?.includes("hearing-loss-simulator") || location?.pathname?.includes("setup-instructions")) {
          _calcTitle = "Simulateur de perte auditive";
        }
        else if (location?.pathname?.includes("questions")) {
          _calcTitle = "Questions générales";
        }
        else _calcTitle = t(`experience-tour.header.title`);
        break;
      case location?.pathname?.includes("product-test"):
        if (location?.pathname?.includes("sound-calibration")) {
          _calcTitle = "Calibration du son";
        }
        else if (location?.pathname?.includes("/device/choices")) {
          _calcTitle = "Choix de la forme";
        }
        else if (location?.pathname?.includes("discovery/bte") || location?.pathname?.includes("discovery/ric") || location?.pathname?.includes("discovery/cic")) {
          _calcTitle = "Choix de la performance";
        }
        else if (location?.pathname?.includes("headset/choices")) {
          _calcTitle = "Écoute du \"casque connecté\"";
        }
        else if (location?.pathname?.includes("protection/discovery")) {
          _calcTitle = "Simuler une protection";
        } 
        else {
          _calcTitle = t(`product-test.header.title`);
        }        
        break;
      case location?.pathname?.includes("/catalog-products"):
        _calcTitle = "Catalogue produits"
      break
    }
    setCalculatedTitle(_calcTitle);
  }, [location.pathname, ready]);

  return calculatedTitle ?? undefined; // prevent managing null value
}
