import cx from "classnames";
import AudioButton from "components/commons/AudioButton/AudioButton";
import Cols from "components/commons/Cols/Cols";
import Typography from "components/commons/Typography/Typography";
import React from "react";
import { useHistory } from "react-router-dom";
import "./ProductTestHeadsetChoices.scss";

type ProductTestHeadsetChoicesProps = {
  className?: string;
};

/**
 * ProductTestHeadsetChoices Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<ProductTestHeadsetChoicesProps>}
 */
const ProductTestHeadsetChoices: React.FC<ProductTestHeadsetChoicesProps> = ({
  className,
}) => {
  const history = useHistory();

  const classes: string = cx("product-test-headset-choices", className);

  function handleRedirect(id: number) {
    history.push(`/product-test/headset/discovery/${id}`, { volume: 0.5 });
  }

  return (
    <Cols className={classes}>
      <div className="product-test-headset-choices__col">
        <AudioButton url="" onClick={() => handleRedirect(1)} />
        <Typography className={"product-test-headset-choices__text"}>
          SONOLY <br />
          Présentation
        </Typography>
      </div>
      <div className="product-test-headset-choices__col">
        <AudioButton url="" onClick={() => handleRedirect(2)} />
        <Typography className={"product-test-headset-choices__text"}>
          Rolling Stones <br />
          Paint It Black
        </Typography>
      </div>
      <div className="product-test-headset-choices__col">
        <AudioButton url="" onClick={() => handleRedirect(3)} />
        <Typography className={"product-test-headset-choices__text"}>
          Celine Dion <br />
          My heart will go on
        </Typography>
      </div>
    </Cols>
  );
};

export default ProductTestHeadsetChoices;
