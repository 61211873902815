import DeviceFormOneImg from "assets/img/device_form_1.png";
import DeviceFormTwoImg from "assets/img/device_form_2.png";
import DeviceFormThreeImg from "assets/img/device_form_3.png";
import DeviceFormFourImg from "assets/img/device_form_4.png";
import DeviceFormFiveImg from "assets/img/device_form_5.png";
import cx from "classnames";
import SelectionCard from "components/containers/Cards/SelectionCard/SelectionCard";
import { Product } from "constants/enums/routePath";
import React from "react";
import { useHistory } from "react-router-dom";
import "./ProductTestDeviceChoices.scss";

type ProductTestDeviceChoicesProps = {
  className?: string;
};

/**
 * ProductTestDeviceChoices Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<ProductTestDeviceChoicesProps>}
 */
const ProductTestDeviceChoices: React.FC<ProductTestDeviceChoicesProps> = ({
  className,
}) => {
  const history = useHistory();

  const classes: string = cx("product-test-device-choices", className);

  function handleRedirect(productType: Product) {
    history.push(`/product-test/discovery/${productType}`, { volume: 0.5 });
  }

  return (
    <div className={classes}>
      <SelectionCard
        button={{
          value: "Sélectionner",
          onClick: () => handleRedirect(Product.RIC),
        }}
        className="product-test-device-choices__selection-card"
        image={DeviceFormOneImg}
        title={"Ecouteur déporté"}
      />
      <SelectionCard
        button={{
          value: "Sélectionner",
          onClick: () => handleRedirect(Product.BTE),
        }}
        className="product-test-device-choices__selection-card"
        image={DeviceFormTwoImg}
        title={"Contour d’oreille"}
      />
      <SelectionCard
        button={{
          value: "Sélectionner",
          onClick: () => handleRedirect(Product.CIC),
        }}
        className="product-test-device-choices__selection-card"
        image={DeviceFormThreeImg}
        title={"Intra auriculaire"}
      />
      <SelectionCard
        button={{
          value: "Sélectionner",
          onClick: () => handleRedirect(Product.LYR),
        }}
        className="product-test-device-choices__selection-card"
        image={DeviceFormFourImg}
        title={"Lyric"}
      />
      <SelectionCard
        button={{
          value: "Sélectionner",
          onClick: () => handleRedirect(Product.HEA),
        }}
        className="product-test-device-choices__selection-card"
        image={DeviceFormFiveImg}
        title={"Hearables"}
      />
    </div>
  );
};

export default ProductTestDeviceChoices;
